import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";

//#region Internal
import SideBar from "./../components/SideBar";
import PageHeader from "./../components/Base/PageHeader";
import LoadingPanel from "./../components/LoadingPanel";

import { Components } from "./../assets/Dimensions";
import { Text } from "./../assets/Strings";
import {
  Notification,
  success,
  error,
  warning,
} from "./../components/Notification/index";
import Crop from "./../components/Crop/index";
import DialogPanel from "./../components/Base/DialogPanel";

import {
  getBookSectionCrop,
  create,
  isExistBookSectionQuestion,
  update,
  updateProd,
  getCroppedImage,
  getCroppedImageProd,
  createSectionPart,
  deleteSectionParts,
  getPage,
  deleteSectionPartsProd,
  createSectionPartProd,
} from "../api/BookSectionCrop";
import { finishTask } from "../api/BookSection";
import { logoutUser } from "./../redux/actions/userAction";
import {
  setCrop,
  clearCroppedList,
  setMultiCropChecked,
  addCroppedList,
  setCroppedList,
  setIsFirst,
} from "./../redux/actions/cropAction";
import { setAnswer } from "./../redux/actions/answerAction";
import { setActivity } from "./../redux/actions/loadingAction";
import { toast } from "react-toastify";
//#endregion
// const queryString = window.location.search;
// console.log("query",queryString);
// const urlParams = new URLSearchParams(queryString);
//#region Inline Style
const styles = {
  container: {
    marginLeft: Components.SideBarWidth + 15,
    paddingTop: 20,
    marginRight: 30,
  },
};
//#endregion
let questionNr = 0;
let questionID = "";
let shownFromTop = false;
let initialMultiCropStatus;
class BookSectionCropPage extends Component {
  constructor(props) {
    super(props);

    this._user = JSON.parse(localStorage.getItem("user"));
    this._pageHeader = "";
    this._pageHeaderMain = "";
    this._cropData = {};
    this._lastQuestion = false;
    this._prev = false;
    this._clickNext = false;
    this._lastQuestionUpdate = false;
    this._adminToken = sessionStorage.getItem("jwtP")
    this.state = {
      croppedDialog: false,
      cropChangeDialog: false,
      finishTaskDialog: false,
      targetQuestion: 1,
      syncWithProduction: false,
      syncWithProductionCrop: false,
      dialogTitle: "Kayıtlı Crop",
    };
  }

  async componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user === null || localStorage.getItem("bookSectionId")&& parseInt(JSON.parse(localStorage.getItem("bookSectionId"))) === 0) {
      // if (user === null || localStorage.getItem("bookSectionId")&& parseInt(JSON.parse(localStorage.getItem("bookSectionId"))) === 0) {
      localStorage.clear();
      this.props.logOutUser();
      this.props.history.push("/login");
      return;
    }

    await this._clearData();
    await this._getCropPage(0, 0);
    this.props.setActivity(false);
  }

  async componentWillUnmount() {
    questionNr = 0;
    questionID = "";
    this.props.setCrop(
      this.props.CropDimensions,
      this.props.AnswerCropDimensions,
      this.props.CroppedImage,
      this.props.AnswerCroppedImage,
      "",
      ""
    );
  }

  _handleClose = (name) => {
    this.setState({
      [name]: false,
    });
  };

  _clearData = async () => {
    this.props.setMultiCropChecked(false);
    initialMultiCropStatus = false;
    this.props.clearCroppedList();
    this.props.setAnswer(null);
    return;
  };

  _setPageHeader = async (pageHeader, pageHeaderMain) => {
    this._pageHeader = [];
    pageHeader.forEach((item) => {
      this._pageHeader += item + " > ";
    });
    this._pageHeader = this._pageHeader.slice(0, -3);

    this._pageHeaderMain = [];
    pageHeaderMain.forEach((item) => {
      this._pageHeaderMain += item;
    });
  };

  _setCropDimensions = (x, y, width, height, naturalWidth, naturalHeight) => {
    let cropDimensions = {
      ...{
        x: x,
        y: y,
        width: width,
        height: height,
      },
      NaturalWidth:
        naturalWidth !== undefined
          ? naturalWidth
          : this.props.CropDimensions.NaturalWidth,
      NaturalHeight:
        naturalHeight !== undefined
          ? naturalHeight
          : this.props.CropDimensions.NaturalHeight,
    };

    return cropDimensions;
  };

  _generateFormData = async (create) => {
    var formData = new FormData();
    try {
      formData.append("UserId", this._user.Id);
      formData.append("BookSectionId", parseInt(this.props.BookSectionId)||JSON.parse(localStorage.getItem("bookSectionId")));
      formData.append("PageNumber", this._cropData.PageNumber);
      formData.append("AnswerPageNumber", this._cropData.AnswerPageNumber);
      formData.append("QuestionNumber", this._cropData.QuestionNumber);
      formData.append("AnswerImageData", this.props.AnswerCroppedImage);
      formData.append("AnswerOption", this.props.Answer);
      formData.append("SyncWithProduction", this.state.syncWithProduction);
      let width = 0.0;
      let height = 0.0;

      await getImageDimensions(this.props.CroppedImage)
      .then(dimensions => {
        width = dimensions.width;
        height = dimensions.height;
      });      

      let answerPath = {
        CropX: this.props.AnswerCropDimensions.x,
        CropY: this.props.AnswerCropDimensions.y,
        CropWidth: this.props.AnswerCropDimensions.width,
        CropHeight: this.props.AnswerCropDimensions.height,
        NaturalWidth: this.props.AnswerCropDimensions.NaturalWidth,
        NaturalHeight: this.props.AnswerCropDimensions.NaturalHeight,
      };
      formData.append("AnswerImagePath", JSON.stringify(answerPath));

      if (!this.props.MultiCropChecked) {
        formData.append("ImageData", this.props.CroppedImage);
        let path = {
          CropX: this.props.CropDimensions.x,
          CropY: this.props.CropDimensions.y,
          CropWidth: this.props.CropDimensions.width,
          CropHeight: this.props.CropDimensions.height,
          NaturalWidth: this.props.CropDimensions.NaturalWidth,
          NaturalHeight: this.props.CropDimensions.NaturalHeight,
          Width: width,
          Height: height
        };
        formData.append("ImagePath", JSON.stringify(path));
      } else {
        if(create){
          formData.append("ImageData", this.props.CroppedList[1].ImageData);
        const cr = this.props.CroppedList[1];
        let path = {
          CropX: cr.Path.x,
          CropY: cr.Path.y,
          CropWidth: cr.Path.width,
          CropHeight: cr.Path.height,
          NaturalWidth: cr.Path.NaturalWidth,
          NaturalHeight: cr.Path.NaturalHeight,
          Width: width,
          Height: height
        };
        if (!cr.Path.width) {
          path = {
            CropX: cr.Path.CropX,
            CropY: cr.Path.CropY,
            CropWidth: cr.Path.CropWidth,
            CropHeight: cr.Path.CropHeight,
            NaturalWidth: cr.Path.NaturalWidth,
            NaturalHeight: cr.Path.NaturalHeight,
            Width: width,
            Height: height
          };
        }
        formData.append("ImagePath", JSON.stringify(path));
        }else{
        formData.append("ImageData", this.props.CroppedList[0].ImageData);
        const cr = this.props.CroppedList[0];
        let path = {
          CropX: cr.Path.x,
          CropY: cr.Path.y,
          CropWidth: cr.Path.width,
          CropHeight: cr.Path.height,
          NaturalWidth: cr.Path.NaturalWidth,
          NaturalHeight: cr.Path.NaturalHeight,
          Width: width,
          Height: height
        };
        if (!cr.Path.width) {
          path = {
            CropX: cr.Path.CropX,
            CropY: cr.Path.CropY,
            CropWidth: cr.Path.CropWidth,
            CropHeight: cr.Path.CropHeight,
            NaturalWidth: cr.Path.NaturalWidth,
            NaturalHeight: cr.Path.NaturalHeight,  
            Width: width,
            Height: height
          };
        }
        formData.append("ImagePath", JSON.stringify(path));
      }
      }
    } catch (error) {
      alert(error.message);
    }
    return formData;
  };

  _validation = () => {
    let answer = this.props.Answer;
    if (answer === null) {
      warning(Text.EmptyAnswer, 2000);
      return false;
    }

    let croppedImage = this.props.CroppedImage;
    if (croppedImage === null) {
      warning(Text.EmptyCroppedImage, 2000);
      return false;
    }

    if (this._cropData.BookSectionDetails.BookSectionTypeId === 2) {
      let answerCroppedImage = this.props.AnswerCroppedImage;
      if (answerCroppedImage === null) {
        warning(Text.EmptyAnswerCroppedImage, 2000);
        return false;
      }
    }

    if (this.props.MultiCropChecked && this.props.CroppedList.length === 0) {
      warning(Text.EmptyCroppedList, 2000);
      return false;
    }

    return true;
  };

  _getDownloadImage = async (imageUrl) => {
    let downloadedImage = new Image();
    console.log("_getDownloadImage", downloadedImage);
    
    downloadedImage.crossOrigin = "Anonymous";
    downloadedImage.src = imageUrl + "?XHRProtection=true";
    downloadedImage.width = 576;
    downloadedImage.height = 821.1;

    return downloadedImage;
  };

  _calculateCroppedPosition = (imagePath, questionNumber, cropPerPage) => {
    let cropDimensions = {};

    if (
      questionNumber % cropPerPage === 1 ||
      (questionNumber % cropPerPage === 0 &&
        imagePath.CropY + imagePath.CropHeight > 700)
    ) {
      let naturalWidth =
        imagePath === null ? undefined : imagePath.NaturalWidth;
      let naturalHeight =
        imagePath === null ? undefined : imagePath.NaturalHeight;
      cropDimensions = this._setCropDimensions(
        62,
        70,
        225,
        200,
        naturalWidth,
        naturalHeight
      );
    } else {
      let x = imagePath.CropX,
        y = 0;

      if (imagePath.CropY + imagePath.CropHeight > 700) {
        x = 302;
        y = 70;
      } else if (imagePath.CropY + imagePath.CropHeight > 320) {
        y = 560;
      } else {
        y = imagePath.CropY + imagePath.CropHeight + 40;
      }

      cropDimensions = this._setCropDimensions(
        x,
        y,
        imagePath.CropWidth,
        180,
        imagePath.NaturalWidth,
        imagePath.NaturalHeight
      );
    }

    return cropDimensions;
  };

  _createSectionParts = async (id) => {
    try {
      for (
        let i = 1;
        i < this.props.CroppedList.filter((x) => x.Path !== undefined).length;
        i++
      ) {
        var formData = new FormData();

        const cr = this.props.CroppedList[i];
        let path = {
          CropX: cr.Path.x,
          CropY: cr.Path.y,
          CropWidth: cr.Path.width,
          CropHeight: cr.Path.height,
          NaturalWidth: cr.Path.NaturalWidth,
          NaturalHeight: cr.Path.NaturalHeight,
        };
        if (!cr.Path.width) {
          path = {
            CropX: cr.Path.CropX,
            CropY: cr.Path.CropY,
            CropWidth: cr.Path.CropWidth,
            CropHeight: cr.Path.CropHeight,
            NaturalWidth: cr.Path.NaturalWidth,
            NaturalHeight: cr.Path.NaturalHeight,
          };
        }

        formData.append("BookSectionCropId", id);
        formData.append("ImageData", this.props.CroppedList[i].ImageData);
        formData.append("ImageUrl", this.props.CroppedList[i].ImageData);
        formData.append("ImagePath", JSON.stringify(path));
        formData.append("SyncWithProduction", this.state.syncWithProduction);
        if(this.state.syncWithProductionCrop){
          await createSectionPartProd(formData, this._user.Token);

        }
        await createSectionPart(formData, this._user.Token);
      }
    } catch (error) {
      alert(error.message + "1");
    }
  };

  _getCrop = async (pageNumber, answerPageNumber, questionNumber) => {
    let req = {
      BookSectionId: parseInt(this.props.BookSectionId)||JSON.parse(localStorage.getItem("bookSectionId")),
      PageNumber: pageNumber,
      AnswerPageNumber: answerPageNumber,
      QuestionNumber: questionNumber,
    };
    let res = await getBookSectionCrop(req, this._user.Token);
    if (res.status === 200) {
      this._cropData = res.data;
      return res.data;
    } else {
      error(res, 2000);
    }
  };

  _getCropPage = async (pageNumber, answerPageNumber) => {
    let data = await this._getCrop(pageNumber, answerPageNumber, 0);
    //yanıt questions için crop olmadığından burada !data is return atılacak
    
    if (data.BookSectionDetails.TotalCropCount >= data.QuestionNumber) {
      if (data.QuestionNumber === data.BookSectionDetails.TotalCropCount) {
        this._lastQuestion = true;
      }

      let imagePath = JSON.parse(data.ImagePath);
      let cropDimensions = this._calculateCroppedPosition(
        imagePath,
        data.QuestionNumber,
        data.BookSectionDetails.CropPerPage
      );

      let cropped = null;
      let downloadedImage = await this._getDownloadImage(data.PageUrl);
      if (this.props.CropSrc !== "") {
        cropped = await this._getCroppedImg(
          downloadedImage,
          cropDimensions,
          "newFile2.jpeg"
        );
      }

      if (cropDimensions.y + cropDimensions.height > window.innerHeight - 30) {
        window.scrollTo(0, window.innerHeight - 50);
      } else {
        window.scrollTo(0, 0);
      }

      let pageHeader = [data.BookDetails.PublisherName, data.BookDetails.Name];
      if (data.BookSectionDetails.BookSectionTypeId !== 4) {
        pageHeader = [
          data.BookDetails.PublisherName,
          data.BookDetails.Name,
          data.BookSectionDetails.CourseTopicName,
          data.BookSectionDetails.SubCourseTopicName,
          data.BookSectionDetails.Name,
        ];
      }
      let pageHeaderMain = ["Soru " + data.VisibleQuestionNumber];
      await this._setPageHeader(pageHeader, pageHeaderMain);

      this.props.setCrop(
        cropDimensions,
        this.props.AnswerCropDimensions,
        cropped,
        this.props.AnswerCroppedImage,
        data.PageUrl,
        data.AnswerPageUrl
      );
      const targetQ = localStorage.getItem("targetQuestion");
      if (targetQ) {
        this._MoveToOtherQuestion(targetQ);
        localStorage.removeItem("targetQuestion");
        this.setState({
          syncWithProduction: true,
        });
      }
    } else {
      if (
        (this._user.Role === "ContentApprovers" ||
          (this._user.Role === "Administrators" &&
            (data.TaskStatus === 3 ||
              data.TaskStatus === 4 ||
              data.TaskStatus === 5))) &&
        data.BookSectionDetails.TotalCropCount < data.QuestionNumber
      ) {
        this._lastQuestion = false;
        await this._setExistedQuestion(1);
        const targetQ = localStorage.getItem("targetQuestion");
        if (targetQ) {
          this._MoveToOtherQuestion(targetQ);
          localStorage.removeItem("targetQuestion");
          this.setState({
            syncWithProduction: true,
          });
        }
      } else {
        this.setState({
          finishTaskDialog: true,
          croppedImage: this.props.CroppedImage,
        
          dialogTitle: "Croplu Resim"
        });
      }
    }

    return;
  };

  _setCropped = async () => {
    let formData = await this._generateFormData(true);

    let res = await create(formData, this._user.Token);

    if (res.status === 200) {
      let req = {
        BookSectionCropId: res.data.id
      };
      await deleteSectionParts(req, this._user.Token);
      if (this.props.MultiCropChecked && this.props.CroppedList.length > 0) {
        await this._createSectionParts(res.data.Id);
      }

      success(Text.SuccessCropped, 2000);
    } else {
      error(res, 2000);
    }
  };

  _setExistedQuestion = async (questionNumber) => {
    if (!questionID) {
      this._MoveToOtherQuestion(questionNumber);
      return;
    }
    this._fromS3CroppedDialog();
    this.setState({ targetQuestion: questionNumber });
  };

  _MoveToOtherQuestion = async (questionNumber) => {
    this.props.clearCroppedList();
    this.setState({ croppedDialog: false });
    shownFromTop = false;
    let data = await this._getCrop(0, 0, questionNumber);
    this.props.setMultiCropChecked(false);
    initialMultiCropStatus = false;
    this._prev = true;
    this.props.setFirstLoad(true);
    let imagePath = JSON.parse(data.ImagePath);
    let cropDimensions = this._setCropDimensions(
      imagePath.CropX,
      imagePath.CropY,
      imagePath.CropWidth,
      imagePath.CropHeight,
      imagePath.NaturalWidth,
      imagePath.NaturalHeight
    );
    let downloadedImage = await this._getDownloadImage(data.PageUrl);
    let cropped = await this._getCroppedImg(
      downloadedImage,
      cropDimensions,
      "newFile2.jpeg"
    );

    let answerCropDimensions = this.props.AnswerCropDimensions;
    let answerCroppedImage = this.props.AnswerCroppedImage;
    if (this._cropData.BookSectionDetails.BookSectionTypeId === 2) {
      let answerImagePath = JSON.parse(data.AnswerImagePath);
      answerCropDimensions = this._setCropDimensions(
        answerImagePath.CropX,
        answerImagePath.CropY,
        answerImagePath.CropWidth,
        answerImagePath.CropHeight,
        answerImagePath.NaturalWidth,
        answerImagePath.NaturalHeight
      );
      let answerDownloadedImage = await this._getDownloadImage(
        data.AnswerPageUrl
      );
      answerCroppedImage = await this._getCroppedImg(
        answerDownloadedImage,
        answerCropDimensions,
        "newFile2.jpeg"
      );
    }

    if (data.CropParts.length > 0) {
      this.props.setMultiCropChecked(true);
      let croppedList = [];
      croppedList.push({
        ImageData: data.ImageUrl,
        Path: JSON.parse(data.ImagePath),
      });
      data.CropParts.forEach((item) => {
        croppedList.push({
          ImageData: item.Url,
          Path: JSON.parse(item.Path),
        });
      });
      initialMultiCropStatus = true;
      this.props.setCroppedList(croppedList);
    } else {
      initialMultiCropStatus = false;
      this.props.setCroppedList([
        { ImageData: data.ImageUrl, Path: JSON.parse(data.ImagePath) },
      ]);
    }

    let pageHeader = [data.BookDetails.PublisherName, data.BookDetails.Name];
    if (data.BookSectionDetails.BookSectionTypeId !== 4) {
      pageHeader = [
        data.BookDetails.PublisherName,
        data.BookDetails.Name,
        data.BookSectionDetails.CourseTopicName,
        data.BookSectionDetails.SubCourseTopicName,
        data.BookSectionDetails.Name,
      ];
    }

    let pageHeaderMain = ["Soru " + data.VisibleQuestionNumber];
    await this._setPageHeader(pageHeader, pageHeaderMain);

    this.props.setAnswer(data.AnswerOption);

    if (cropDimensions.y + cropDimensions.height > window.innerHeight - 30) {
      window.scrollTo(0, window.innerHeight - 50);
    } else {
      window.scrollTo(0, 0);
    }
    this.props.setCrop(
      cropDimensions,
      answerCropDimensions,
      cropped,
      answerCroppedImage,
      data.PageUrl,
      data.AnswerPageUrl
    );
    if (!this.props.MultiCropChecked) this.props.setFirstLoad(false);
  };

  _previousQuestion = async () => {
    questionID = parseInt(this.props.BookSectionId)||JSON.parse(localStorage.getItem("bookSectionId"));
    questionNr = this._cropData.QuestionNumber;
    if (this._cropData.QuestionNumber - 1 === 0) {
      warning(Text.InFirstQuestion, 2000);
      return;
    }

    this._lastQuestion = false;
    this._clickNext = false;
    this.props.setActivity(true);

    let req = {
      BookSectionId: parseInt(this.props.BookSectionId)||JSON.parse(localStorage.getItem("bookSectionId")),
      QuestionNumber: this._cropData.QuestionNumber,
    };
    var res = await isExistBookSectionQuestion(req, this._user.Token);
    if (res.status === 200) {
      if (res.data.Id === 0) {
        if (!this._prev) {
          await this._MoveToOtherQuestion(this._cropData.QuestionNumber - 1);
          this.props.setActivity(false);
          return;
        }

        if (!this._validation()) {
          this.props.setActivity(false);
          return;
        }

        await this._setCropped();
        await this._MoveToOtherQuestion(this._cropData.QuestionNumber - 1);
        this.props.setActivity(false);
        return;
      }

      this._prev = true;

      let imagePath = JSON.parse(res.data.ImagePath);
      let answerImagePath = JSON.parse(res.data.AnswerImagePath);
      let isChanged = false;

      if (res.data.AnswerOption !== this.props.Answer) {
        isChanged = true;
      }

      if (
        res.data.Id > 0 &&
        (imagePath.CropX !== this.props.CropDimensions.x ||
          imagePath.CropY !== this.props.CropDimensions.y ||
          imagePath.CropWidth !== this.props.CropDimensions.width ||
          imagePath.CropHeight !== this.props.CropDimensions.height)
      ) {
        isChanged = true;
      }
      if (initialMultiCropStatus !== this.props.MultiCropChecked) {
        isChanged = true;
      }
      if (
        res.data.Id > 0 &&
        res.data.AnswerImagePath !== null &&
        (answerImagePath.CropX !== this.props.AnswerCropDimensions.x ||
          answerImagePath.CropY !== this.props.AnswerCropDimensions.y ||
          answerImagePath.CropWidth !== this.props.AnswerCropDimensions.width ||
          answerImagePath.CropHeight !== this.props.AnswerCropDimensions.height)
      ) {
        isChanged = true;
      }

      if (
        this.props.MultiCropChecked &&
        res.data.Id > 0 &&
        (res.data.CropPartPaths.length > 0 || this.props.CroppedList.length > 0)
      ) {
        isChanged = true;
      }

      if (isChanged) {
        this.setState({
          cropChangeDialog: true,
        });
      } else {
        await this._MoveToOtherQuestion(this._cropData.QuestionNumber - 1);
      }
    } else {
      error(res, 2000);
    }
    this.props.setActivity(false);
  };

  _nextQuestion = async () => {
    questionID = parseInt(this.props.BookSectionId)||JSON.parse(localStorage.getItem("bookSectionId"));
    questionNr = this._cropData.QuestionNumber;
    this._clickNext = true;
    this.props.setActivity(true);
    let req = {
      BookSectionId: parseInt(this.props.BookSectionId)||JSON.parse(localStorage.getItem("bookSectionId")),
      QuestionNumber: this._cropData.QuestionNumber,
    };
    var res = await isExistBookSectionQuestion(req, this._user.Token);
    if (res.status === 200) {
      if (res.data.Id === 0) {
        if (!this._validation()) {
          this.props.setActivity(false);
          return;
        }

        this._prev = false;

        if (this._lastQuestion) {
          this.setState({
            finishTaskDialog: true,
            croppedImage: this.props.CroppedImage,
            
            dialogTitle: "Croplu Resim"
          });
        } else {
          this._prev = false;
          await this._setCropped();
          await this._clearData();
          await this._getCropPage(0, 0);
        }

        this.props.setActivity(false);
        return;
      }

      let imagePath = JSON.parse(res.data.ImagePath);
      let answerImagePath = JSON.parse(res.data.AnswerImagePath);
      let isChanged = false;
      if (res.data.AnswerOption !== this.props.Answer) {
        isChanged = true;
      }

      if (
        res.data.Id > 0 &&
        (imagePath.CropX !== this.props.CropDimensions.x ||
          imagePath.CropY !== this.props.CropDimensions.y ||
          imagePath.CropWidth !== this.props.CropDimensions.width ||
          imagePath.CropHeight !== this.props.CropDimensions.height)
      ) {
        isChanged = true;
      }

      if (
        res.data.Id > 0 &&
        res.data.AnswerImagePath !== null &&
        (answerImagePath.CropX !== this.props.AnswerCropDimensions.x ||
          answerImagePath.CropY !== this.props.AnswerCropDimensions.y ||
          answerImagePath.CropWidth !== this.props.AnswerCropDimensions.width ||
          answerImagePath.CropHeight !== this.props.AnswerCropDimensions.height)
      ) {
        isChanged = true;
      }
      if (initialMultiCropStatus !== this.props.MultiCropChecked) {
        isChanged = true;
      }

      if (
        this.props.MultiCropChecked &&
        res.data.Id > 0 &&
        (res.data.CropPartPaths.length > 0 || this.props.CroppedList.length > 0)
      ) {
        isChanged = true;
      }

      this._lastQuestionUpdate = false;
      if (isChanged) {
        if (this._lastQuestion) {
          this._lastQuestionUpdate = true;
          this.setState({
            finishTaskDialog: true,
            croppedImage: this.props.CroppedImage,
           
            dialogTitle: "Croplu Resim"
          });
        } else {
          this.setState({
            cropChangeDialog: true,
          });
        }
      } else {
        if (this._lastQuestion) {
          this.setState({
            finishTaskDialog: true,
            croppedImage: this.props.CroppedImage,
            
            dialogTitle: "Croplu Resim"
          });
        } else {
          await this._notUpdateCroppedImage();
        }
      }
    } else {
      error(res, 2000);
    }
    this.props.setActivity(false);
  };

  _getCroppedImg = async (image, crop, fileName) => {
    const canvas = document.createElement("canvas");
    const scaleX = crop.NaturalWidth / image.width;
    const scaleY = crop.NaturalHeight / image.height;
    canvas.width = crop.width * scaleX;
    canvas.height = crop.height * scaleY;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject("Empty canvas");
          return;
        }

        if (blob.size < 12700) {
          let dummyDimensions = { ...this.props.CropDimensions, dummy: 1 };
          let dummyAnswerDimensions = {
            ...this.props.AnswerCropDimensions,
            dummy: 1,
          };
          this.props.setCrop(
            dummyDimensions,
            dummyAnswerDimensions,
            this.props.CroppedImage,
            this.props.AnswerCroppedImage,
            this.props.ImageUrl,
            this.props.AnswerImageUrl
          );
        }

        blob.name = fileName;
        blob.lastModifiedDate = new Date();
        resolve(blob);
      }, "image/jpeg");
    });
  };

  _croppedDialog = async () => {
    questionID = parseInt(this.props.BookSectionId)||JSON.parse(localStorage.getItem("bookSectionId"));
    questionNr = this._cropData.QuestionNumber;
    shownFromTop = true;
    let answerCroppedImage = null;
    if (this.props.AnswerCroppedImage !== null) {
      answerCroppedImage = this.props.AnswerCroppedImage;
    }

    let croppedList = [];
    if (this.props.MultiCropChecked) {
      croppedList = this.props.CroppedList;
    }

    this.setState({
      croppedDialog: true,
      croppedImage: this.props.CroppedImage,
      answerCroppedImage: answerCroppedImage,
      croppedList: croppedList,
      dialogTitle: "Croplu Resim",
    });
  };

  _getPage = async (pageNumber, answerPageNumber, isQuestion = false) => {
    let req = {
      BookSectionId: parseInt(this.props.BookSectionId)||JSON.parse(localStorage.getItem("bookSectionId")),
      PageNumber: pageNumber,
      AnswerPageNumber: answerPageNumber,
    };
    let res = await getPage(req, this._user.Token);
    if (res.status === 200) {
      let questionDimensions = this.props.CropDimensions;
      let answerDimensions = this.props.AnswerCropDimensions;

      if (isQuestion) {
        questionDimensions = this._setCropDimensions(
          62,
          70,
          225,
          200,
          undefined,
          undefined
        );
      }

      if (!isQuestion) {
        answerDimensions = this._setCropDimensions(
          62,
          70,
          225,
          200,
          undefined,
          undefined
        );
      }

      this.props.setCrop(
        questionDimensions,
        answerDimensions,
        this.props.CroppedImage,
        this.props.AnswerCroppedImage,
        res.data.PageUrl,
        res.data.AnswerPageUrl
      );
      return true;
    } else {
      error(res, 2000);
      return false;
    }
  };

  _questionPreviousPage = async () => {
    if (this._cropData.PageNumber !== 1) {
      let pageNumber = this._cropData.PageNumber - 1;
      let answerPageNumber =
        this._cropData.AnswerPageNumber === 9999
          ? this._cropData.PageNumber
          : this._cropData.AnswerPageNumber;
      if (await this._getPage(pageNumber, answerPageNumber, true)) {
        this._cropData.PageNumber = pageNumber;
        this._cropData.AnswerPageNumber = answerPageNumber;
      }
    } else {
      warning(Text.InFirstPage, 2000);
    }
  };

  _questionNextPage = async () => {
    if (this._cropData.PageNumber !== this._cropData.BookDetails.PageCount) {
      let pageNumber = this._cropData.PageNumber + 1;
      let answerPageNumber =
        this._cropData.AnswerPageNumber === 9999
          ? this._cropData.PageNumber
          : this._cropData.AnswerPageNumber;
      if (await this._getPage(pageNumber, answerPageNumber, true)) {
        this._cropData.PageNumber = pageNumber;
        this._cropData.AnswerPageNumber = answerPageNumber;
      }
    } else {
      warning(Text.InLastPage, 2000);
    }
  };

  _questionFirstPage = async () => {
    let pageNumber = 1;
    let answerPageNumber =
      this._cropData.AnswerPageNumber === 9999
        ? this._cropData.PageNumber
        : this._cropData.AnswerPageNumber;
    if (await this._getPage(pageNumber, answerPageNumber, true)) {
      this._cropData.PageNumber = pageNumber;
      this._cropData.AnswerPageNumber = answerPageNumber;
    }
  };

  _questionLastPage = async () => {
    let pageNumber = this._cropData.BookDetails.PageCount;
    let answerPageNumber =
      this._cropData.AnswerPageNumber === 9999
        ? this._cropData.PageNumber
        : this._cropData.AnswerPageNumber;
    if (await this._getPage(pageNumber, answerPageNumber, true)) {
      this._cropData.PageNumber = pageNumber;
      this._cropData.AnswerPageNumber = answerPageNumber;
    }
  };

  _answerPreviousPage = async () => {
    if (this._cropData.AnswerPageNumber !== 1) {
      let pageNumber = this._cropData.PageNumber;
      let answerPageNumber = this._cropData.AnswerPageNumber - 1;
      if (await this._getPage(pageNumber, answerPageNumber)) {
        this._cropData.PageNumber = pageNumber;
        this._cropData.AnswerPageNumber = answerPageNumber;
      }
    } else {
      warning(Text.InFirstPage, 2000);
    }
  };

  _answerNextPage = async () => {
    if (
      this._cropData.AnswerPageNumber !== this._cropData.BookDetails.PageCount
    ) {
      let pageNumber = this._cropData.PageNumber;
      let answerPageNumber = this._cropData.AnswerPageNumber + 1;
      if (await this._getPage(pageNumber, answerPageNumber)) {
        this._cropData.PageNumber = pageNumber;
        this._cropData.AnswerPageNumber = answerPageNumber;
      }
    } else {
      warning(Text.InLastPage, 2000);
    }
  };

  _answerFirstPage = async () => {
    let pageNumber = this._cropData.PageNumber;
    let answerPageNumber = 1;
    if (await this._getPage(pageNumber, answerPageNumber)) {
      this._cropData.PageNumber = pageNumber;
      this._cropData.AnswerPageNumber = answerPageNumber;
    }
  };

  _answerLastPage = async () => {
    let pageNumber = this._cropData.PageNumber;
    let answerPageNumber = this._cropData.BookDetails.PageCount;
    if (await this._getPage(pageNumber, answerPageNumber)) {
      this._cropData.PageNumber = pageNumber;
      this._cropData.AnswerPageNumber = answerPageNumber;
    }
  };

  _updateCroppedImageDialog = async () => {
    this.setState({
      cropChangeDialog: true,
    });
  };

  _updateCroppedImage = async () => {
    if (!this._validation) {
      return;
    }

    let formData = await this._generateFormData();
    if(this.state.syncWithProductionCrop){
      let res = await updateProd(formData, sessionStorage.getItem('jwtP'));

    if (res.status === 200) {
      let req = {
        BookSectionCropId: res.data,
        SyncWithProduction: this.state.syncWithProductionCrop 
      };
      await deleteSectionPartsProd(req, sessionStorage.getItem('jwtP'));
      if (this.props.MultiCropChecked && this.props.CroppedList.length > 0) {
        await this._createSectionParts(res.data);
      }

      success(Text.UpdateCropped, 2000);
      await this._notUpdateCroppedImage();
    } else {
      error(res, 2000);
    }
    }
    let res = await update(formData, this._user.Token);

    if (res.status === 200) {
      let req = {
        BookSectionCropId: res.data,
        SyncWithProduction: this.state.syncWithProduction
      };
      await deleteSectionParts(req, this._user.Token);
      if (this.props.MultiCropChecked && this.props.CroppedList.length > 0) {
        await this._createSectionParts(res.data);
      }

      success(Text.UpdateCropped, 2000);
      await this._notUpdateCroppedImage();
    } else {
      error(res, 2000);
    }
  };

  _finishTask = async () => {
    if (!this._lastQuestionUpdate && !this._prev) {
      await this._setCropped();
      await this._clearData();
      await this._getCropPage(0, 0);
    }

    if (this._lastQuestionUpdate) {
      if (!this._validation) {
        return;
      }

      let formData = await this._generateFormData();
      let res = await update(formData, this._user.Token);

      if (res.status === 200) {
        let req = {
          BookSectionCropId: res.data,
        };
        await deleteSectionParts(req, this._user.Token);
        if (this.props.MultiCropChecked && this.props.CroppedList.length > 0) {
          await this._createSectionParts(res.data);
        }

        success(Text.UpdateCropped, 2000);
      } else {
        error(res, 2000);
      }
    }

    let req = {
      BookSectionId: parseInt(this.props.BookSectionId)||JSON.parse(localStorage.getItem("bookSectionId")),
      UserId: this._user.Id,
    };
    await finishTask(req, this._user.Token);

    this.props.history.push({
      pathname: "/worklist",
      state: {
        isFinished: true,
      },
    });
  };

  _notUpdateCroppedImage = async () => {
    let questionNumber = this._clickNext
      ? this._cropData.QuestionNumber + 1
      : this._cropData.QuestionNumber - 1;
    let data = await this._getCrop(0, 0, questionNumber);

    if (data.QuestionNumber > data.BookSectionDetails.TotalCropCount) {
      this.setState({
        finishTaskDialog: true,
        croppedImage: this.props.CroppedImage,
       
        dialogTitle: "Croplu Resim"
      });
      return;
    }

    if (data.QuestionNumber === data.BookSectionDetails.TotalCropCount) {
      this._lastQuestion = true;
    }

    if (data.ImagePath !== null) {
      await this._setExistedQuestion(this._cropData.QuestionNumber);
    } else {
      this._prev = false;
      await this._clearData();
      await this._getCropPage(0, 0);
    }

    this.setState({ croppedDialog: false, cropChangeDialog: false });
  };

  _fromS3CroppedDialog = async () => {
    console.log("s3CroppedDialog")
    this.setState({ dialogTitle: "Kayıtlı Görüntü" });
    this._croppedImageUrl = null;
    this._croppedImageUrlProd = null;
    this._answerCroppedImageUrl = null;
    this._answerCroppedImageUrlProd = null;
    this._croppedList = [];
    this._croppedListProd = [];
    let req = {
      BookSectionId: parseInt(questionID),
      QuestionNumber: questionNr,
    };
    let res = await getCroppedImage(req, this._user.Token);
    console.log("cropdRes",req)
    if (res.status) {
      this._croppedImageUrl = res.data.ImageUrl;
      this._answerCroppedImageUrl = res.data.AnswerImageUrl;

      if (res.data.ImagePartUrls.length > 0) {
        let mainImage = {
          ImageData: this._croppedImageUrl,
          Path: null,
        };
        this._croppedList.push(mainImage);

        res.data.ImagePartUrls.forEach((item) => {
          let croppedImage = {
            ImageData: item,
            Path: null,
          };
          this._croppedList.push(croppedImage);
        });
      }
    } else {
      error(res, 2000);
    }
    if(this._user.Role=="Administrators"){
    let resProd = await getCroppedImageProd(req, this._adminToken);
    if (resProd.status == 200) {
      this._croppedImageUrlProd = resProd.data.ImageUrl;
      this._answerCroppedImageUrlProd = resProd.data.AnswerImageUrl;

      if (resProd.data.ImagePartUrls.length > 0) {
        let mainImage = {
          ImageData: this._croppedImageUrl,
          Path: null,
        };
        this._croppedListProd.push(mainImage);

        resProd.data.ImagePartUrls.forEach((item) => {
          let croppedImageProd = {
            ImageData: item,
            Path: null,
          };
          this._croppedListProd.push(croppedImageProd);
        });
      }
    } else if(resProd == "Error: Request failed with status code 401") {
      toast.error("Lütfen tekrar giriş yapınız")
    }else {
      error(resProd,1000);
    }
  }
    this.setState({
      croppedDialog: true,
      croppedImage: this._croppedImageUrl,
      croppedImageProd: this._croppedImageUrlProd,
      answerCroppedImage: this._answerCroppedImageUrl,
      croppedList: this._croppedList,
      croppedListProd: this._croppedListProd
    });
  };

  _finishTaskCancel = () => {
    this.setState({
      finishTaskDialog: false,
    });
  };

  _finishTaskApprove = async () => {
    await this._finishTask();
    this.setState({
      finishTaskDialog: false,
    });
  };

  _fromS3CroppedDialogFromTop = () => {
    questionID = parseInt(this.props.BookSectionId)||JSON.parse(localStorage.getItem("bookSectionId"));
    questionNr = this._cropData.QuestionNumber;
    shownFromTop = true;
    this.setState({ dialogTitle: "Kayıtlı Görüntü" });
    this._fromS3CroppedDialog();
    console.log("s3CroppedDialogFromTop")
  };
  render() {
    return (
      <div>
        <LoadingPanel />

        <SideBar />

        <div style={{ ...styles.container }}>
          <PageHeader
            mainTitle={this._pageHeaderMain}
            title={this._pageHeader}
            isCropPage={true}
          />

          <Crop
            previousQuestion={this._previousQuestion}
            nextQuestion={this._nextQuestion}
            croppedDialog={this._croppedDialog}
            cropSrc={this.props.CropSrc}
            answerSrc={this.props.AnswerCropSrc}
            questionFirstPage={this._questionFirstPage}
            questionLastPage={this._questionLastPage}
            questionPreviousPage={this._questionPreviousPage}
            questionNextPage={this._questionNextPage}
            answerFirstPage={this._answerFirstPage}
            answerLastPage={this._answerLastPage}
            answerPreviousPage={this._answerPreviousPage}
            answerNextPage={this._answerNextPage}
            questionPageNumber={this._cropData.PageNumber || 0}
            answerPageNumber={
              (this._cropData.AnswerPageNumber === 9999
                ? this._cropData.PageNumber
                : this._cropData.AnswerPageNumber) || 0
            }
            nextQuestionText={
              this._lastQuestion ? Text.FinishSection : Text.NextQuestion
            }
            typeId={
              this._cropData.BookSectionDetails !== undefined
                ? this._cropData.BookSectionDetails.BookSectionTypeId
                : undefined
            }
            s3CroppedDialog={this._prev && this._fromS3CroppedDialogFromTop}
            SyncWithProduction={this.state.syncWithProduction}
            onChangeSyncWithProduction={(s) => {
              this.setState({
                syncWithProduction: !this.state.syncWithProduction,
              });
            }}
          />
        </div>

        <DialogPanel
          id="croppedDialog"
          isOpen={this.state.croppedDialog}
          onClose={() => {}}
          title={this.state.dialogTitle}
          croppedImage={this.state.croppedImage}
          croppedImageProd={this.state.croppedImageProd}
          answerCroppedImage={this.state.answerCroppedImage}
          croppedList={this.state.croppedList}
          croppedListProd={this.state.croppedListProd}
          actions={[
            {
              text: "ok",
              onClick: () => {
                if (shownFromTop) {
                  console.log("croplu Resim")
                  this._handleClose("croppedDialog");
                  shownFromTop = false;
                  return;
                }
                this._MoveToOtherQuestion(this.state.targetQuestion);
              },
            },
          ]}
        />
         {/* <DialogPanel
          id="croppedDialogProd"
          isOpen={this.state.croppedDialog}
          onClose={() => {}}
          title={this.state.dialogTitle}
          croppedImage={this.state.croppedImage}
          answerCroppedImage={this.state.answerCroppedImage}
          croppedList={this.state.croppedList}
          actions={[
            {
              text: "ok",
              onClick: () => {
                if (shownFromTop) {
                  console.log("croplu Resim2")
                  this._handleClose("croppedDialog");
                  shownFromTop = false;
                  return;
                }
                this._MoveToOtherQuestion(this.state.targetQuestion);
              },
            },
          ]}
        /> */}
        {console.log("cropedList",this.props.CroppedList)}
        <DialogPanel
          id="cropChangeDialog"
          isOpen={this.state.cropChangeDialog}
          onClose={() => this._handleClose("cropChangeDialog")}
          title={Text.CropUpdateQuestionTitle}
          contentText={Text.CropUpdateQuestion}
           croppedList={this.props.CroppedList}
          // croppedImage={this.state.croppedImage}
          croppedImage={this.props.CroppedImage}
          syncWithProductionCrop = {this.state.syncWithProductionCrop}
          onChangeSyncWithProductionCrop={(s) => {
            this.setState({
              syncWithProductionCrop: !this.state.syncWithProductionCrop,
            });
          }}
          actions={[
            { onClick: this._notUpdateCroppedImage, text: Text.Cancel },
            { onClick: this._updateCroppedImage, text: Text.Update },
          ]}
        />

        <DialogPanel
          id="finishTaskDialog"
          isOpen={this.state.finishTaskDialog}
          onClose={() => this._handleClose("finishTaskDialog")}
          title={Text.FinishTaskTitle}
          croppedImage={this.state.croppedImage}
          answerCroppedImage={this.state.answerCroppedImage}
          croppedList={this.state.croppedList}
          actions={[
            { onClick: this._finishTaskCancel, text: Text.Cancel },
            { onClick: this._finishTaskApprove, text: Text.FinishTask },
          ]}
        />

        <Notification />
      </div>
    );
  }
}

const getImageDimensions = (blob) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    
    img.onload = () => {
      const dimensions = {
        width: img.width,
        height: img.height
      };
      URL.revokeObjectURL(img.src);
      resolve(dimensions);
    };
    
    img.onerror = (error) => {
      URL.revokeObjectURL(img.src);
      reject(new Error("Exception while reading dimensions: " + error.message));
    };
    
    img.src = URL.createObjectURL(blob);
  });
};

const mapStateToProps = (state) => ({
  User: state.userReducer.User,
  Answer: state.answerReducer.Answer,
  CropDimensions: state.cropReducer.CropDimensions,
  AnswerCropDimensions: state.cropReducer.AnswerCropDimensions,
  CroppedImage: state.cropReducer.CroppedImage,
  AnswerCroppedImage: state.cropReducer.AnswerCroppedImage,
  CropSrc: state.cropReducer.CropSrc,
  AnswerCropSrc: state.cropReducer.AnswerCropSrc,
  BookSectionId: state.bookSectionReducer.SelectedId,
  CroppedList: state.cropReducer.CroppedList,
  MultiCropChecked: state.cropReducer.MultiCropChecked,
});

const mapDispatchToProps = (dispatch) => ({
  logOutUser: () => dispatch(logoutUser()),
  setCrop: (
    cropDimensions,
    answerCropDimensions,
    croppedImage,
    answerCroppedImage,
    cropSrc,
    answerCropSrc
  ) =>
    dispatch(
      setCrop(
        cropDimensions,
        answerCropDimensions,
        croppedImage,
        answerCroppedImage,
        cropSrc,
        answerCropSrc
      )
    ),
  setAnswer: (answer) => dispatch(setAnswer(answer)),
  clearCroppedList: () => dispatch(clearCroppedList()),
  setMultiCropChecked: (multiCropChecked) =>
    dispatch(setMultiCropChecked(multiCropChecked)),
  addCroppedList: (cropped) => dispatch(addCroppedList(cropped)),
  setCroppedList: (croppedList) => dispatch(setCroppedList(croppedList)),
  setActivity: (state) => dispatch(setActivity(state)),
  setFirstLoad: (state) => dispatch(setIsFirst(state)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BookSectionCropPage));
