import React, { useEffect, useState } from "react";
import PageHeader from "../components/Base/PageHeader";
import SideBar from "../components/SideBar";
import { useMediaQuery } from "react-responsive";
import {
  Notification,
  success,
  error,
  warning,
} from "../components/Notification/index";
import { Components } from "../assets/Dimensions";

import {
  getMemberByEmail,
  updateCreditStatus,
  addNewCredit,
  clearUserCache,
  addNewCreditBulk,
  getAllUserEmail,
  resetUserPassword,
  creditAccordingToDate
} from "../api/User";
import moment from "moment";
import DatePicker from "react-datepicker";
import "./credit.css"
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../components/Loader";
import { toast } from "react-toastify";
import DynamicTable from "../components/Table";
import MuiDialog from "@material-ui/core/Dialog";
import { withStyles } from '@material-ui/styles';
import SwitchComponent from "../components/switchToggle";
import SwitchToggleComponent from "../components/switchToggle";
import { enumToOptions } from "../helpers/enumToOptions";
import LoginTypes from "../enums/LoginType";
function CreditsPage() {
  const [email, setEmail] = useState("");
  const [userId, setUserId] = useState();
  const [enddate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [codeLength, setCodeLenght] = useState("");
  const [enddateBulk, setEndDateBulk] = useState("");
  const [startDateBulk, setStartDateBulk] = useState("");
  const [count, setCount] = useState("");
  const [promationName, setPromationName] = useState("");
  const [notFound, setNotFound] = useState("");
  const [bulkCredit, setBulkCredit] = useState([]);
  const [value, setValue] = useState("1");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nav, setNav] = useState(false);
  const [emailData, setEmailData] = useState([])
  const [displayCreditData, setCreditData] = useState(false)
  const [displayUserEmailData, setUserEmailData] = useState(false)
  const [price, setPrice] = useState()
  const [startDateForBill, setStartDateForBill] = useState("")
  const [endDateForBill, setEndDateForBill] = useState("")
  const [paidUsers, setPaidUsers] = useState(null)
  const [confirmModal, setConfirmModal] = useState(false)
  const [selectedLoginType, setSelectedLoginType] = useState(LoginTypes.PhoneNumber)
  const _user = JSON.parse(localStorage.getItem("user"));

  const pJWT = sessionStorage.getItem("jwtP");

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 700px)' })
  const getData = async (mail) => {
    setPaidUsers(null)
    setLoading(true);
    try {
      const res = await getMemberByEmail(mail ? mail : email,selectedLoginType, pJWT, true);
      const Error = await res.response;
      setLoading();
      if (res.status == 200) {
        setData(res.data);
      }
      if (Error.status == 400) {
        setNotFound(Error.data.Error);
      } else {

        toast.error("Lütfen Tekrar Giriş Yapınız")
      }
    } catch (error) {
    }
  };
  const userAllEmail = async () => {
    setPaidUsers(null)
    setLoading(true)
    try {
      const res = await getAllUserEmail(email, selectedLoginType,pJWT);
      const Error = await res.response;
      setLoading();
      if (res.data.ResponseStatus == 1) {
        setEmailData(res.data.ResponseData)

      }
      else if (Error.status == 400) {
        setNotFound(Error.data.Error)
      } else {

        toast.error("Lütfen Tekrar Giriş Yapınız")
      }
    } catch (error) {
    }
  }
  const resetPassword = async (email) => {
    setPaidUsers(null)
    setLoading(true)
    //try {
      const res = await resetUserPassword(email, pJWT);
      //const Error = await res.response;
      //setLoading();
      console.log("resReset",res)
      if (res.status == 200) {
        toast.success("Şifre sıfırlama başarılı")

      }
      else if (res.status == 400) {
        toast.error("kullanıcı bulunamadı")
      } 
      else {

        toast.error("Lütfen Tekrar Giriş Yapınız")
      }
    //} catch (error) {
    //}
  }
  const formatDate = (date) => {
    if (!date) return ''; // Handle cases where no date is selected

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };
  const paymentCheck = async (startDate, endDate, price) => {
    const payLoad = {
      "StartDate": formatDate(startDate),
      "EndDate": formatDate(endDate),
      "Value": parseInt(price, 10)
    }
    const res = await creditAccordingToDate(payLoad, pJWT)
    if (res.data.ResponseStatus == 1) {
      console.log("istek başarılı", res.data.ResponseData)
      setPaidUsers(res.data.ResponseData)
    }
  }
  useEffect(() => {
    if (data.length > 0) {
      setUserId(data[0].UserId);
    }
  }, [data]);
  const updateStatus = async (credit) => {
    setPaidUsers(null)
    setLoading(true);
    const res = await updateCreditStatus(credit.Id, pJWT, true);
    success("Stutus Updated");
    setLoading();
    getData();
  };
  const reRender = async (email) => {
    setPaidUsers(null)
    console.log("mail", email)
    setPaidUsers(null)
    setData([]);
    setNotFound("");
    await getData(email);
    //setUserEmailData(false)
    setCreditData(true)
  };
  const clearCahce = async () => {
    setPaidUsers(null)
    setLoading(true);
    const res = await clearUserCache(userId, pJWT, true);
    if (res.status == 200) {
      success("Cache Cleared");

    }
    setLoading();
  };
  const getallEmailsOfUser = () => {
    setPaidUsers(null)
    setLoading(true);
    userAllEmail()
    setCreditData(false)
    setUserEmailData(true)
  }
  const addCredit = async () => {
    setPaidUsers(null)
    const params = {
      //UserId: userId,
      UserName: email,
      EndDate: moment(enddate).add("26", "hours").add("59", "minute"),
      StartDate: moment(startDate).add("3", "hours"),
      LoginType: selectedLoginType
    };

    setLoading(true);
    const res = await addNewCredit(params, pJWT, true);
    if (res.status == 200) success("Yeni Credi Eklendi");
    setLoading();
  };
  const addCreditBulk = async () => {
    setPaidUsers(null)
    const params = {
      CodeLength: codeLength,
      Count: value,
      EndDate: moment(enddateBulk).add("26", "hours").add("59", "minute"),
      StartDate: moment(startDateBulk).add("3", "hours"),
      PromotionName: promationName,
    };

    setLoading(true);
    const res = await addNewCreditBulk(params, pJWT, true);

    if (res.status == 200) {
      success("Bulk Credit Eklendi");
      setBulkCredit(res.data);
    }
    setLoading();
  };
  const min = 1;
  const max = 10;
  const handleChange = (event) => {
    const value = Math.max(min, Math.min(max, Number(event.target.value)));
    setValue(value);
  };

  const handleConfirmModal = ()=>{
    setConfirmModal(true)
  }

  const addCoachingToUser = async(email) => {
    const params = {
      //UserId: userId,
      UserName: email,
      ProductGroupId : 1,
      LoginType: selectedLoginType
    };
    if(email.length>0){
      const res = await addNewCredit(params, pJWT, true);
      if(res.data.ResponseStatus == 1) {
        
        toast.success("Kullanıcıya koçluk başarıyla eklenmiştir eklenmiştir")
      }else{
        toast.error("Bir hata oluştu")
      }
    }else{
      toast.error("Lütfen Email giriniz")
    }
  }

  const options = enumToOptions(LoginTypes);

  const handleValueChange = (newValue) => {
    console.log('Selected Value:', newValue);
    setSelectedLoginType(newValue)
  };



  const Dialog = withStyles({
    paperWidthSm: {
        maxWidth: 720,
        minWidth: 300,
        minHeight: 220,
    },
})(MuiDialog);


  const styles = {
    container: {
      //state e bool değer ekle menü butonuna basıldığında css 
      //içinde container a margin eklesin hemde navı açsın

      marginLeft: isTabletOrMobile ? 0 : Components.SideBarWidth + 15,
      paddingTop: 20,
      marginRight: 30,

    },
    table: {
      padding: 12,
      flex: 1,
      margin: 8,
      overflowWrap: "break-word",
      minWidth: "130px",
    },
    column: {
      marginRight: 8,
      padding: 12,
      flex: 1,
    },
    card: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      border: "solid 1px gray",
      padding: 12,
      margin: 10,
      backgroundColor: "#FFFFFF",
      borderRadious: 20,

    },
    cardDiv: {
      display: "flex",
      flexDirection: isTabletOrMobile ? "column" : "row",
      margin: 12
    },
    screen: {
      // display:"flex",
      // flexDirection: isTabletOrMobile?"column":"row",
      // margin:5
    },
    input: {
      display: "block",
      width: "50%",
      overflow: "hidden",
      resize: "both",


    },
    ChangeDateModalStyle: {
      padding: 20,
      margin: "auto"
  },
  }
  console.log("data", data)
  return (
    <div style={styles.screen}>
      {isTabletOrMobile ?
        <div>
          <span style={{ "position": "absolute", top: 10, right: 10 }} onClick={() => (setNav(!nav))}>menu</span>
          {nav && <SideBar />}

        </div>
        : <SideBar />}
        {confirmModal && (
                    <Dialog
                        open={confirmModal}
                        onClose={() => setConfirmModal(false)}
                    >
                        <div style={styles.ChangeDateModalStyle}>
                      
                    
                            Bu Kullanıcıya koçluk tanımlamayı onaylıyormusunuz?
                            <div style={{ "marginTop": 5 }}>
                                <button
                                    onClick={() => {
                                      addCoachingToUser(email)
                                        //setDisplayDateChange(false);
                                        setConfirmModal(false);
                                      
                                    }}
                                    style={{ marginRight: 10 }}
                                // style={styles.deleteConfirm}
                                >
                                    Tanımla
                                </button>
                                <button
                                    onClick={() => {
                                      setConfirmModal(false);
                                      
                                    }}
                                // style={styles.deleteConfirmClose}
                                >
                                    Vazgeç
                                </button>
                            </div>
                        </div>
                    </Dialog>
                )}
      <div style={{ ...styles.container }}>
      <SwitchToggleComponent
        options={options}
        initialValue={LoginTypes.PhoneNumber}
        onValueChange={handleValueChange}
      />
        <input
          style={styles.column}
          type="text"
          placeholder="Email Adress"
          value={email}
          onChange={(e) => {
            setEmail(e.currentTarget.value);
          }}
        />
        
       
      
        <div style={{ display: "flex", margin: 12 }}>
          <button style={styles.column} onClick={() => {
            reRender();
            setUserEmailData(false);
          }}>
            Get User Credit List
          </button>

          <button style={styles.column} onClick={clearCahce}>
            Clear Cache
          </button>
          <button style={styles.column} onClick={getallEmailsOfUser}>
            Get All User Email
          </button>
          <button style={{...styles.column,backgroundColor:"	#6495ED"}} onClick={() => {
            handleConfirmModal()
            //addCoachingToUser(email)
          }}>
            Add Coaching to user
          </button>
        </div>
        {displayUserEmailData && <div style={{ "marginTop": "15px", "marginBottom": "15px" }}>

          {isTabletOrMobile ?
            <div style={styles.cardDiv}>
              {emailData.length > 0 ?
                emailData.map((userEmail) => {
                  return (
                    <div style={styles.card}>
                      <p>{`Name: ${userEmail.Name}`}</p>
                      <p>{`Surname: ${userEmail.Surname}`}</p>
                      <p>{`Email: ${userEmail.Email}`}</p>
                      <p>{`Old Email: ${userEmail.OldEmail}`}</p>
                      <button onClick={() => {
                        setEmail(userEmail.Email)
                        reRender(userEmail.Email)
                      }
                      }>Get Credit</button>
                      <button onClick={() => resetPassword(userEmail.Id)}>Reset Password</button>
                      {userEmail.Email == email && <div style={{ "padding": "15px" }}>
                        {isTabletOrMobile &&
                          <div style={styles.cardDiv}>
                            {data.length > 0 ? data.map((credit) => {
                              return (
                                <div style={styles.card}>
                                  <p>{`ID: ${credit.Id}`}</p>
                                  <p>{`CreditResourceType: ${credit.CreditResourceType}`}</p>
                                  <p>{`EndDate: ${moment(credit.EndDate).format("DD/MM/YYYY hh:mm")}`}</p>
                                  <p>{`PromotionName: ${credit.PromotionName}`}</p>
                                  <p>{`ProductId: ${credit.ProductId}`}</p>
                                  <p>{`PromotionCode: ${credit.PromotionCode}`}</p>
                                  <p>{`StartDate: ${moment(credit.StartDate).format("DD/MM/YYYY hh:mm")}`}</p>
                                  <p>{`Status: ${credit.Status}`}</p>

                                  <button
                                    onClick={() => {
                                      updateStatus(credit);
                                    }}
                                  >
                                    {loading ? "Yükleniyor" : "Update Status"}
                                  </button>
                                  <p>{`GPA: ${credit.OrderId ? credit.OrderId : ""}`}</p>
                                  <p>{`User Id: ${credit.UserId}`}</p>

                                </div>
                              )
                            }) : <div>
                              <span
                                style={{ backgroundColor: "red", fontSize: "30px", color: "white" }}
                              >
                                {notFound}
                              </span>
                            </div>}
                          </div>}
                      </div>}
                    </div>
                  )
                })
                : <></>}

            </div> :
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Surname</th>
                  <th>Email</th>
                  <th>Old Email</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {emailData ? emailData.map((item, index) => (
                  <>
                    <tr key={index}>
                      <th>{item.Name}</th>
                      <th>{item.Surname}</th>
                      <th>{item.Email}</th>
                      <th>{item.OldEmail}</th>
                      <th><button onClick={() => {
                        setEmail(item.Email)
                        reRender(item.Email)
                      }
                      }>Get Credit</button></th>
                      <th><button onClick={() => resetPassword(item.Id)}>Reset Password</button></th>
                    </tr>
                    {displayCreditData && email == item.Email && <tr>
                      <td colSpan="6">
                        <div
                          style={{
                            "overflow-x": "auto",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <div
                            style={{
                              borderBottom: "1px solid",
                              display: "flex",
                              marginTop: 4,
                            }}
                          >
                            <span style={styles.column}>ID</span>
                            <span style={styles.column}>CreditResourceType</span>
                            <span style={styles.column}>EndDate</span>
                            <span style={styles.column}>PromotionName</span>
                            <span style={styles.column}>ProductId</span>
                            <span style={styles.column}>PromotionCode</span>
                            <span style={styles.column}>StartDate</span>
                            <span style={styles.column}>Status</span>
                            <span style={styles.column}>Update Status</span>
                            <span style={styles.column}>GPA</span>
                            <span style={styles.column}>User Id</span>
                          </div>
                          {data.length > 0 ? data.sort((a, b) => b.Id - a.Id).map((credit, index) => {
                            return (
                              <div
                                style={{
                                  borderBottom: "1px solid",
                                  display: "flex",
                                  marginTop: 4,
                                }}
                                key={credit.Id.toString()}
                              >
                                <span style={styles.column}>{credit.Id}</span>
                                <span style={styles.column}>{credit.CreditResourceType}</span>
                                <span style={styles.column}>
                                  {moment(credit.EndDate).format("DD/MM/YYYY hh:mm")}
                                </span>
                                <span style={styles.column}>{credit.PromotionName}</span>
                                <span style={styles.column}>{credit.ProductId}</span>
                                <span style={styles.column}>{credit.PromotionCode}</span>
                                <span style={styles.column}>
                                  {moment(credit.StartDate).format("DD/MM/YYYY hh:mm")}
                                </span>
                                <span style={styles.column}>{credit.Status}</span>
                                <button
                                  onClick={() => {
                                    updateStatus(credit);
                                  }}
                                >
                                  {loading ? "Yükleniyor" : "Update Status"}
                                </button>
                                <span style={styles.column}>
                                  {credit.OrderId ? credit.OrderId : ""}
                                </span>
                                <span style={styles.column}>{credit.UserId}</span>
                              </div>
                            );
                          }) : <div>
                            <span
                              style={{ backgroundColor: "red", fontSize: "30px", color: "white" }}
                            >
                              {notFound}
                            </span>
                          </div>}
                        </div>

                      </td>
                    </tr>}
                  </>

                )) : null}
              </tbody>
            </table>
          }
        </div>}
        <h3>Add New Credit</h3>
        <div style={styles.cardDiv}>
          <div style={styles.card}>
            <h4>For user</h4>

            <p>
              Start Date
              <DatePicker
                wrapperClassName="input"
                title="Start Date"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
              />
            </p>
            <p>
              End Date
              <DatePicker
                selected={enddate}
                onChange={(date) => setEndDate(date)}
              />
            </p>
            <button onClick={addCredit}>Add Credit</button>
          </div>

          <div style={styles.card}>
            <h4>Bulk Sale</h4>
            <input
              style={styles.input}
              type="text"
              value={codeLength}
              placeholder="Code Length"
              onChange={(e) => {
                setCodeLenght(e.currentTarget.value);
              }}
            />
            <br></br>
            <input
              style={styles.input}
              type="number"
              max="10"
              min="1"
              value={value}
              placeholder="Count"
              onChange={(e) => {
                handleChange(e);
                setCount(e.currentTarget.value);
              }}
            />
            <br></br>
            <input
              style={styles.input}
              type="text"
              value={promationName}
              placeholder="Promotion Name"
              onChange={(e) => {
                setPromationName(e.currentTarget.value);
              }}
            />
            <p>
              Start Date
              <DatePicker
                selected={startDateBulk}
                onChange={(date) => setStartDateBulk(date)}
              />
            </p>
            <p>
              End Date
              <DatePicker
                selected={enddateBulk}
                onChange={(date) => setEndDateBulk(date)}
              />
            </p>
            <button style={{}} onClick={addCreditBulk}>
              Add Credit
            </button>
          </div>
          {bulkCredit.length > 0 && (
            <div style={styles.card}>
              <div style={{ "overflow-y": "auto", maxHeight: "400px" }}>
                {bulkCredit.map((credit) => (
                  <li>{credit}</li>
                ))}
              </div>
            </div>
          )}
          <div style={styles.card}>
            <div style={{ "overflow-y": "auto", maxHeight: "400px" }}>
              <p>
                Start Date
                <DatePicker


                  selected={startDateForBill}
                  onChange={(date) => setStartDateForBill(date)}
                  timeInputLabel="Time:"
                  dateFormat="dd/MM/yyyy HH:mm"
                  showTimeInput

                />
              </p>
              <p>
                End Date
                <DatePicker
                  selected={endDateForBill}
                  onChange={(date) => setEndDateForBill(date)}
                  timeInputLabel="Time:"
                  dateFormat="dd/MM/yyyy HH:mm "
                  showTimeInput

                />
              </p>
              <input
                style={styles.column}
                type="text"
                placeholder="Alınan Ödeme Tutarı"
                value={price}
                onChange={(e) => {
                  setPrice(e.currentTarget.value);
                }} />
              <button onClick={() => {
                paymentCheck(startDateForBill, endDateForBill, price)
                console.log("price", price)
                console.log("startDate", formatDate(startDateForBill))
                console.log("endDate", endDateForBill)
              }}>Check Payment</button>
            </div>
          </div>
        </div>
        <div style={{ "marginBottom": "15px", marginTop: "15px" }}>
          {paidUsers ?
            <div>
              <p><strong>Paid Users </strong></p>
              {paidUsers.length > 0 ? <DynamicTable
                data={paidUsers}
                isTablet={isTabletOrMobile}
              />
                : <strong>No Paid Users</strong>}
              <hr />

            </div> : null}
        </div>
        {/* <div style={{"marginBottom":"15px",marginTop:"15px"}}>
        {paidUsers? 
        paidUsers.length>0&&<DynamicTable
              data={paidUsers}
              isTablet={isTabletOrMobile}
              />:<strong>No Paid Users</strong>}
              </div> */}
        <div>
          <span
            style={{ backgroundColor: "red", fontSize: "30px", color: "white" }}
          >
            {notFound}
          </span>
        </div>
        {displayCreditData && !displayUserEmailData && <div>

          {isTabletOrMobile &&
            <div style={styles.cardDiv}>
              {data.length > 0 ? data.map((credit) => {
                return (
                  <div style={styles.card}>
                    <p>{`ID: ${credit.Id}`}</p>
                    <p>{`CreditResourceType: ${credit.CreditResourceType}`}</p>
                    <p>{`EndDate: ${moment(credit.EndDate).format("DD/MM/YYYY hh:mm")}`}</p>
                    <p>{`PromotionName: ${credit.PromotionName}`}</p>
                    <p>{`ProductId: ${credit.ProductId}`}</p>
                    <p>{`PromotionCode: ${credit.PromotionCode}`}</p>
                    <p>{`StartDate: ${moment(credit.StartDate).format("DD/MM/YYYY hh:mm")}`}</p>
                    <p>{`Status: ${credit.Status}`}</p>

                    <button
                      onClick={() => {
                        updateStatus(credit);
                      }}
                    >
                      {loading ? "Yükleniyor" : "Update Status"}
                    </button>
                    <p>{`GPA: ${credit.OrderId ? credit.OrderId : ""}`}</p>
                    <p>{`User Id: ${credit.UserId}`}</p>

                  </div>
                )
              }) : <div>
                <span
                  style={{ backgroundColor: "red", fontSize: "30px", color: "white" }}
                >
                  {notFound}
                </span>
              </div>}
            </div>}
          {!isTabletOrMobile && <div
            style={{
              "overflow-x": "auto",
              whiteSpace: "nowrap",
            }}
          >
            <div
              style={{
                borderBottom: "1px solid",
                display: "flex",
                marginTop: 4,
              }}
            >
              <span style={styles.column}>ID</span>
              <span style={styles.column}>CreditResourceType</span>
              <span style={styles.column}>EndDate</span>
              <span style={styles.column}>PromotionName</span>
              <span style={styles.column}>ProductId</span>
              <span style={styles.column}>PromotionCode</span>
              <span style={styles.column}>StartDate</span>
              <span style={styles.column}>Status</span>
              <span style={styles.column}>Update Status</span>
              <span style={styles.column}>GPA</span>
              <span style={styles.column}>User Id</span>
            </div>
            {data.sort((a, b) => b.Id - a.Id).map((credit, index) => {
              return (
                <div
                  style={{
                    borderBottom: "1px solid",
                    display: "flex",
                    marginTop: 4,
                  }}
                  key={credit.Id.toString()}
                >
                  <span style={styles.column}>{credit.Id}</span>
                  <span style={styles.column}>{credit.CreditResourceType}</span>
                  <span style={styles.column}>
                    {moment(credit.EndDate).format("DD/MM/YYYY hh:mm")}
                  </span>
                  <span style={styles.column}>{credit.PromotionName}</span>
                  <span style={styles.column}>{credit.ProductId}</span>
                  <span style={styles.column}>{credit.PromotionCode}</span>
                  <span style={styles.column}>
                    {moment(credit.StartDate).format("DD/MM/YYYY hh:mm")}
                  </span>
                  <span style={styles.column}>{credit.Status}</span>
                  <button
                    onClick={() => {
                      updateStatus(credit);
                    }}
                  >
                    {loading ? "Yükleniyor" : "Update Status"}
                  </button>
                  <span style={styles.column}>
                    {credit.OrderId ? credit.OrderId : ""}
                  </span>
                  <span style={styles.column}>{credit.UserId}</span>
                </div>
              );
            })}
          </div>}


        </div>}
      </div>
      {loading ? <Loader></Loader> : <></>}
      <Notification />
    </div>
  );
}

export default CreditsPage;
